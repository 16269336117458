<template>
  <div>
    <v-alert type="error" :key="index" v-for="(msg, index) in errors.non_field_errors">
      {{ msg }}
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    errors: Object
  }
};
</script>