<template>
    <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
            <v-card>
                <v-form @submit.prevent="submit">
                    <v-toolbar dark color="primary">
                        <v-toolbar-title>Foolproof</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-text-field
                            :error-messages="errors.username"
                            label="Email"
                            prepend-icon="mdi-account"
                            v-model="params.username">
                        </v-text-field>
                        <v-text-field
                            :error-messages="errors.password"
                            label="Password"
                            type="password"
                            prepend-icon="mdi-lock"
                            v-model="params.password">
                        </v-text-field>
                    </v-card-text>
                    <v-card-title class="d-block">
                        <form-errors :errors="errors"></form-errors>
                    </v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn type="submit" color="primary">Login</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
import FormErrors from "@/components/FormErrors";
import {mapMutations} from 'vuex';

export default {
    name: 'Login',
    components: {FormErrors},
    data: () => ({
        params: {
            username: '',
            password: '',
        },
        errors: {},
    }),
    methods: {
        ...mapMutations(['setUser']),
        async submit() {
            this.errors = {};
            const res = await this.$http.post('/login', this.params);
            if (res.ok)
                this.setUser(res.result.data);
            else
                this.errors = res.result;
        }
    }
}
</script>